var $ = require('jquery');

$(function() {
    var $header = $('#header');

    // Nav Toggle Button
    $('#toggle').click(function(){
        $header.toggleClass('is-open');
    });

    $('a[href^="#"]').click(function() {
        // スクロールの速度
        var speed = 800; // ミリ秒
        // アンカーの値取得
        var href= $(this).attr("href");
        // 移動先を取得
        var target = $(href == "#" || href == "" ? 'html' : href);
        // 移動先を数値で取得
        var position = target.offset().top;
        // スムーススクロール
        $('body,html').animate({scrollTop:position}, speed, 'swing');
        return false;
    });

});