import "./module/responsive.js"
import "./module/userAgentChecker.min.js"

import Swiper from 'swiper';
var myswiper = new Swiper('.swiper-container', {
    centeredSlides: true,
    effect: 'fade',
    speed: 4000,
    simulateTouch: false,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    }
});